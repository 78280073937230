import { DatabaseIcon } from "@heroicons/react/outline";
import { useModal, WorkflowContext } from "../contexts";
import WorkflowSelectModal from "./WorkflowSelectModal";
import { useContext } from "react";

function WorkflowNoSource() {
  const { openModal } = useModal();
  const workflowContext = useContext(WorkflowContext);

  return (
    <div className="flex flex-col gap-4 justify-center h-full">
      <div className="flex items-center">
        <DatabaseIcon className="w-6 h-6 mr-2" />
        <div className="font-gilroy font-semibold text-lg">
          Connect a Source
        </div>
      </div>
      <div className="flex flex-col gap-2 font-semibold text-gray-600">
        Create a batch of documents from a spreadsheet
        <div className="flex items-center gap-2">
          <div className="h-px bg-gray-600 w-full" />
          <div className="flex-shrink-0">or</div>
          <div className="h-px bg-gray-600 w-full" />
        </div>
        Auto-Create documents from an event or form&nbsp;submission
      </div>
      <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Source" subtitle="Create or select a source where your data will come from" showSources {...workflowContext} />)}>
        Connect
      </button>
    </div>
  );
}

export default WorkflowNoSource;
