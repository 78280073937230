import { useState } from "react";
import { useModal } from "../contexts";
import Modal from "./Modal";
import SmallTextInputField from "./SmallTextInputField";

interface Props {
  onAddLink: (text: string, url: string) => void;
  onClose?: () => void;
}

function WorkflowModalLink(props: Props) {
  const { onAddLink, onClose } = props;

  const { closeModal } = useModal();

  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [displayText, setDisplayText] = useState<string>("");
  const [url, setUrl] = useState<string>("");

  function handleChangeDisplayText(value: string) {
    setHasChanged(true);
    setDisplayText(value);
  }

  function handleChangeUrl(value: string) {
    setHasChanged(true);
    setUrl(value);
  }

  function handleInsertLink() {
    let externalUrl;
    if (url.startsWith("http://") || url.startsWith("https://"))
      externalUrl = url;
    else
      externalUrl = "http://" + url;

    onAddLink(displayText, externalUrl);
    handleOnClose();
  }

  function handleOnClose() {
    if (onClose)
      onClose();

    closeModal();
  }

  return (
    <Modal
      title="Insert Link"
      secondaryButtonProps={{ text: "Cancel", onClick: handleOnClose }}
      primaryButtonProps={{ disabled: !hasChanged, onClick: handleInsertLink, text: "Insert" }}
    >
      <SmallTextInputField label="Display Text" value={displayText} onChange={handleChangeDisplayText} />
      <SmallTextInputField label="URL" value={url} onChange={handleChangeUrl} />
    </ Modal>
  )
}

export default WorkflowModalLink;
