import useUser from "../hooks/useUser";
import { EmailConfig, Workflow } from "../interfaces";
import EmailAliasPopover from "./EmailAliasPopover";

type Props = {
  workflow: Workflow,
  emailConfig: EmailConfig;
  onChange: (field: string, value: any) => void,

}

function WorkflowEmailFrom(props: Props) {
  const { user, hasPermission } = useUser();
  const { emailConfig, onChange, workflow } = props;
  const allowEditing = hasPermission(workflow.team, "workflows.edit");

  function onSetEmailFromValue(emailFromAlias: string | null) {
    onChange('emailFromAlias', emailFromAlias ?? '');
  }

  return (
    <div className="flex items-center w-full relative">
      <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-from">From</label>
      {emailConfig.emailType === "GMAIL"
        ? (
          <EmailAliasPopover
            value={emailConfig.emailFromAlias}
            setValue={onSetEmailFromValue}
            workflow={workflow}
            sendFromOwner={emailConfig.sendFromOwner}
            aliasDisplay={emailConfig.aliasDisplay}
            allowEditing={allowEditing}
          />
        ) : (
          <div>{user!.microsoftCredentials?.microsoftEmailAddress ?? "Your Microsoft Outlook Account"}</div>
        )
      }
    </div>
  );
}

export default WorkflowEmailFrom;
