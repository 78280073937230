import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Email } from "../interfaces";
import WorkflowEmailToolbarButton from "./WorkflowEmailToolbarButton";
import { useModal } from "../contexts";
import { CodeIcon } from "@heroicons/react/outline";
import useUser from "../hooks/useUser";
import ProLabel from "./ProLabel";
import ToggleButton from "./ToggleButton";
import PayWallModalPro from "./PayWallModalPro";
import { Popover } from '@headlessui/react'
import { FormatType } from "../hooks/useRichTextfield";
import WorkflowModalLink from "./WorkflowModalLink";
import WorkflowModalImage from "./WorkflowModalImage";


type Props = {
  signatureRequestProps?: SignatureRequestConfigProps;
  emailProps?: EmailConfigProps;
  applyFormat: (format: FormatType) => void;
  addLink: (text: string, url: string) => void;
  addImage: (url: string) => void;
  allowModeSwitch?: boolean;
  htmlMode?: boolean;
  onContentModeSwitch?: () => void;
  restoreLastSelection: () => void;
  showInsertCTA?: boolean;
  insertCustom?: (node: HTMLElement) => void;
}

type EmailConfigProps = {
  isHTMLMode: boolean;
  setEmail: Dispatch<SetStateAction<Email | undefined>>;
  sanitizedHTML: string;
}

type SignatureRequestConfigProps = {
  customFormatting?: boolean;
  updateCustomFormating?: (value: boolean) => void;
}

function WorkflowEmailToolbar(props: Props) {
  const { user } = useUser();
  const { openModal } = useModal();
  const [formatMenuOpen, setFormatMenuOpen] = useState(false);

  const { emailProps, signatureRequestProps, restoreLastSelection, applyFormat, addLink, addImage, allowModeSwitch, htmlMode, onContentModeSwitch, showInsertCTA, insertCustom } = props;
  const { updateCustomFormating, customFormatting } = signatureRequestProps ?? {};

  function toggleLinkMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    restoreLastSelection();
    if (emailProps)
      openModal(<WorkflowModalLink onAddLink={(...props) => {
        restoreLastSelection(); addLink(...props);
      }} />)
    else if (signatureRequestProps) {
      if (!user?.subscription?.featureSignatures)
        return openModal(<PayWallModalPro />);

      if (!customFormatting)
        updateCustomFormating!(true);

      openModal(<WorkflowModalLink onAddLink={addLink} />)
    }
  }

  function toggleImageMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

    if (signatureRequestProps) {
      if (!user?.subscription?.featureSignatures)
        return openModal(<PayWallModalPro />);

      if (!customFormatting)
        updateCustomFormating!(true);

      openModal(<WorkflowModalImage addImage={addImage} />)
    }
  }

  const handleFormatClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (htmlMode) return;

    setFormatMenuOpen(prev => !prev);
  }

  const handleFormatItemClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, format: FormatType) => {
    e.preventDefault();
    applyFormat(format);
  }

  const handleInsertCTA = () => {
    if (!insertCustom) return;

    const newCta = document.createElement('a');
    newCta.innerText = 'Review and Sign';

    newCta.style.padding = '10px 20px';
    newCta.style.backgroundColor = '#1875FB';
    newCta.style.color = 'white';
    newCta.style.minWidth = '200px';
    newCta.style.borderRadius = '0.25rem';
    newCta.style.border = 'none';
    newCta.style.fontSize = '16px';
    newCta.style.cursor = 'pointer';
    newCta.id = "cta-button"

    insertCustom(newCta);
  }

  useEffect(() => {
    if (htmlMode)
      setFormatMenuOpen(false);
  }, [htmlMode]);

  return (
    <div className='flex mx-2 h-full gap-2 items-center'>
      <Popover className="relative" >
        <WorkflowEmailToolbarButton
          iconName="format_color_text"
          tooltipText="Formatting options"
          isDisabled={htmlMode}
          isMenu
          onClick={handleFormatClick}
        />
        {formatMenuOpen && (
          <Popover.Panel className="absolute z-10 bottom-full bg-white rounded p-2 flex shadow" static>
            <WorkflowEmailToolbarButton
              onClick={(e) => handleFormatItemClick(e, 'bold')}
              iconName="format_bold"
              tooltipText="Bold"
              isDisabled={htmlMode}
            />
            <WorkflowEmailToolbarButton
              onClick={(e) => handleFormatItemClick(e, 'italic')}
              iconName="format_italic"
              tooltipText="Italic"
              isDisabled={htmlMode}
            />
            <WorkflowEmailToolbarButton
              onClick={(e) => handleFormatItemClick(e, 'underline')}
              iconName="format_underline"
              tooltipText="Underline"
              isDisabled={htmlMode}
            />
            <div className="h-6 mt-auto mb-auto w-0.5 mx-2 bg-gray-300" />
            <WorkflowEmailToolbarButton
              onClick={(e) => handleFormatItemClick(e, 'justifyLeft')}
              iconName="format_align_left"
              tooltipText="Align left"
              isDisabled={htmlMode}
            />
            <WorkflowEmailToolbarButton
              onClick={(e) => handleFormatItemClick(e, 'justifyCenter')}
              iconName="format_align_center"
              tooltipText="Align center"
              isDisabled={htmlMode}
            />
            <WorkflowEmailToolbarButton
              onClick={(e) => handleFormatItemClick(e, 'justifyRight')}
              iconName="format_align_right"
              tooltipText="Align right"
              isDisabled={htmlMode}
            />

          </Popover.Panel>
        )}
      </Popover>
      <WorkflowEmailToolbarButton iconName="link" tooltipText="Insert link" onClick={toggleLinkMenu} isDisabled={htmlMode} />
      <WorkflowEmailToolbarButton iconName="insert_photo" tooltipText="Insert photo" onClick={toggleImageMenu} isDisabled={htmlMode} />
      {showInsertCTA && <WorkflowEmailToolbarButton iconName="star_icon" tooltipText="Insert call to action" onClick={handleInsertCTA} isDisabled={htmlMode} />}

      <div className='mx-2 bg-gray-400 h-[20px] w-px my-2' />

      {allowModeSwitch && (
        <button
          className="flex items-center gap-1 font-semibold font-gilroy text-gray-600 text-sm hover:text-blue hover:underline cursor-pointer shrink-0 mr-6"
          onClick={onContentModeSwitch}
        >
          <CodeIcon className='h-6 w-6' />
          Switch to {htmlMode ? 'default editor' : <div className='relative'>HTML editor <div className='absolute bg-blue text-white px-2 rounded shadow font-gilroy font-semibold text-xs -top-4 -right-6'>BETA</div></div>}
        </button>
      )}

      {updateCustomFormating && (
        <>
          {!user?.subscription?.featureSignatures &&
            <>
              <ProLabel />
              <div className='text-xs text-gray-400 font-gilroy font-semibold mx-2 w-60 mr-auto'>
                Pro users may also add content after the customisable signature request button
              </div>
            </>
          }

          {user?.subscription?.featureSignatures &&
            <>
              <ToggleButton value={!!customFormatting} onChange={updateCustomFormating} />
              <div className='text-xs text-gray-600 font-gilroy font-semibold mx-2 flex-shrink-0'>
                Custom formatting
              </div>
            </>
          }
        </>
      )}
    </div>
  );
}

export default WorkflowEmailToolbar;
