import React, { useId, useRef, useState } from "react";

interface Props {
  label?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  required?: boolean;
  disabled?: boolean;
  value: string;
  invalid?: boolean;
  autoFocus?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onEnter?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  hidePrompt?: boolean;
  tabIndex?: number;
}

function SmallTextInputField(props: Props) {
  const id = useId();
  const ref = useRef<HTMLInputElement>(null);

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [showSave, setShowSave] = useState<boolean>(false);

  const isInvalid = props.invalid || (props.required && props.value.length === 0);

  function onEnter() {
    ref.current?.blur();

    if (!hasChanges)
      return;

    setHasChanges(false);

    setShowSave(true);
    setTimeout(() => setShowSave(false), 5000);

    props.onEnter?.();
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter")
      onEnter();

    props.onKeyDown?.(e);
  }

  function onChange(e: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) {
    setHasChanges(true);
    props.onChange(e.currentTarget.value)
  }

  return (
    <div className={"relative flex flex-col " + props.className}>
      {props.label && <label htmlFor={id} className="text-xs font-semibold text-gray-400 bg-gradient-to-b from-white via-white to-transparent grad ml-2 -mb-2 mr-auto px-1 z-10 w-auto">
        {props.label}
      </label>}
      <input tabIndex={props.tabIndex} ref={ref} id={id} type='text' className={`border-2 rounded py-1 px-2 w-full outline-none text-sm ${isInvalid ? "border-yellow" : "border-gray-400"} ${props.disabled ? "bg-gray-200" : "bg-white"} ${props.onEnter && (hasChanges || showSave) ? "pr-14" : ""} ${props.inputClassName}`} placeholder={props.placeholder} value={props.value} onChange={onChange} disabled={props.disabled} autoComplete="off" onBlur={props.onBlur ?? onEnter} onFocus={props.onFocus} onKeyDown={onKeyDown} autoFocus={props.autoFocus} />
      {!props.hidePrompt &&
        <>
          {(props.onEnter && hasChanges) &&
            <div className={`flex items-center text-xs absolute right-2 ${!!props.label ? "top-[14px]" : "top-[6px]"} w-12 justify-center border rounded shadow px-1 pt-px bg-blue opacity-80 text-white cursor-pointer`} onClick={onEnter}>
              Enter
            </div>
          }
          {(props.onEnter && (!hasChanges && showSave)) &&
            <div className={`flex items-center text-xs absolute right-2 ${!!props.label ? "top-[14px]" : "top-[6px]"} w-12 justify-center border rounded shadow px-1 pt-px bg-green opacity-80 text-white cursor-pointer`} onClick={onEnter}>
              Saved
            </div>
          }
        </>
      }
    </div>
  );
};

export default SmallTextInputField;
