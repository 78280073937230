import { ChevronDownIcon, LinkIcon, MenuAlt1Icon, MenuIcon, PaperClipIcon, PhotographIcon, StarIcon } from "@heroicons/react/outline";
import { ReactNode } from "react";

interface Props {
  iconName: string;
  tooltipText?: string;
  isMenu?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isDisabled?: boolean;
}

function WorkflowEmailToolbarButton(props: Props) {
  const { isDisabled, iconName, tooltipText, isMenu, className, onClick } = props;
  const customIconClassName = `${isDisabled ? "text-gray-400" : "text-gray-700"}`;
  const iconClassName = `h-5 w-5 ${customIconClassName}`;
  const iconBgClassName = `${isDisabled ? "bg-gray-400" : "bg-gray-600"}`;
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isDisabled) {
      onClick?.(e);
    }
  }
  const ICON_MAP: Record<string, ReactNode> = {
    "attach_file": <PaperClipIcon className={iconClassName} />,
    "insert_photo": <PhotographIcon className={iconClassName} />,
    "format_color_text": <span className={`select-none underline decoration-2 text-xl font-mono ${customIconClassName}`}>A</span>,
    "link": <LinkIcon className={iconClassName} />,
    "format_bold": <span className={`select-none font-black text-xl font-mono ${customIconClassName}`}>B</span>,
    "format_underline": <span className={`select-none underline text-xl font-mono ${customIconClassName}`}>U</span>,
    "format_italic": <span className={`select-none italic text-xl font-mono ${customIconClassName}`}>I</span>,
    "format_align_left": <MenuAlt1Icon className={iconClassName} />,
    "format_align_right": <MenuAlt1Icon className={`scale-x-[-1] ${iconClassName}`} />,
    "format_align_center": <MenuIcon className={iconClassName} />,
    "format_list_numbered": (
      <div className="flex flex-col justify-center w-full h-full m-0.5">
        <div className="flex items-center gap-0.5"> <p className="text-[7px] leading-none">1</p> <div className={`w-full h-0.5 ${iconBgClassName}`} /> </div>
        <div className="flex items-center gap-0.5"> <p className="text-[7px] leading-none">2</p> <div className={`w-full h-0.5 ${iconBgClassName}`} /> </div>
        <div className="flex items-center gap-0.5"> <p className="text-[7px] leading-none">3</p> <div className={`w-full h-0.5 ${iconBgClassName}`} /> </div>
      </div>
    ),
    "star_icon": <StarIcon className={iconClassName} />,
    "format_list_bulleted": (
      <div className="flex flex-col justify-center gap-[3px] h-full w-full flex-grow m-0.5">
        <div className="flex items-center gap-0.5"> <div className={`h-1 w-1 flex-shrink-0 rounded-full ${iconBgClassName}`} /> <div className={`w-full h-0.5 ${iconBgClassName}`} /> </div>
        <div className="flex items-center gap-0.5"> <div className={`h-1 w-1 flex-shrink-0 rounded-full ${iconBgClassName}`} /> <div className={`w-full h-0.5 ${iconBgClassName}`} /> </div>
        <div className="flex items-center gap-0.5"> <div className={`h-1 w-1 flex-shrink-0 rounded-full ${iconBgClassName}`} /> <div className={`w-full h-0.5 ${iconBgClassName}`} /> </div>
      </div>
    )
  };

  const Icon = iconName in ICON_MAP ? ICON_MAP[iconName] : <p>{iconName}</p>;

  return (
    <button className={`group relative flex items-center justify-center ${isMenu ? "w-10" : "w-7"} h-7 cursor-pointer rounded ${isDisabled ? 'cursor-not-allowed bg-transparent' : 'hover:bg-gray-100'} ${className}`} onClick={handleClick}>
      {Icon}

      {isMenu &&
        <ChevronDownIcon className={`h-4 w-4 ${customIconClassName}`} />
      }
      {tooltipText &&
        <span className={`invisible ${!isDisabled && "group-hover:visible"} bg-gray-600 text-xs text-white text-center rounded py-1 px-2 absolute left-0 z-50 bottom-full whitespace-nowrap`}>{tooltipText}</span>
      }
    </button>
  );
}

export default WorkflowEmailToolbarButton;
