import { useContext, useRef, useState } from "react";
import { ExternalLinkIcon, DuplicateIcon, PhotographIcon } from "@heroicons/react/outline";
import { ChatGPTField } from "../interfaces";
import { WorkflowContext, useModal } from "../contexts";
import { Document } from "../interfaces";
import CopyToClipboard from "react-copy-to-clipboard";
import WorkflowChatGPTModal from "./WorkflowChatGPTModal";
import { postData } from "../backend";


interface Props {
  field: ChatGPTField;
  document?: Document;
}

function WorkflowChatGPTFieldTableRow(props: Props) {
  const { field, document } = props;

  const documentType = document?.documentType;
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { openModal } = useModal();

  const ref = useRef<HTMLDivElement>(null);

  function handleOpenEditModal() {
    openModal(<WorkflowChatGPTModal workflow={workflow} mutateWorkflow={mutateWorkflow} field={field} />);
  }

  function handleCopyTag() {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  }

  function handleInsertImage() {
    if (!props.document)
      return;

    setIsCopied(true);
    postData(`/workflows/documents/${props.document.id}/insert-image-placeholder/`, {
      tag: field.fieldName,
      sqaure: true
    })
      .catch(console.error)
      .finally(() => setTimeout(() => setIsCopied(false), 5000));
  }

  return (
    <div ref={ref} key={field.id} className="flex flex-col justify-center py-1 px-2 text-sm border-b last:border-b-0 w-full">
      <div className="flex w-full items-center gap-1">
        <ExternalLinkIcon className={`w-4 h-4 flex-shrink-0 transform transition-all cursor-pointer`} onClick={handleOpenEditModal} />
        <div className="font-semibold pt-2 pb-1 truncate cursor-pointer" onClick={handleOpenEditModal}>{field.fieldName}</div>
        <div className="ml-auto relative group">
          <img src="https://static.portant.co/openai-icon.svg" className="w-4 h-4" alt="Open AI Icon" />
          <div className="hidden group-hover:flex absolute -top-1 right-6 bg-black text-white text-xs rounded-sm z-10 px-2 py-1 whitespace-nowrap select-none">
            <svg height="8px" width="4px" xmlns="http://www.w3.org/2000/svg" className="absolute text-black" style={{ right: "-4px", top: "calc(100% / 2 - 4px)" }}>
              <polygon points="0,0 4,4 0,8" fill="currentColor" />
            </svg>
            AI Content
          </div>
        </div>
        {(documentType && field.fieldType !== "IMAGE") &&
          <CopyToClipboard text={"{{" + (field.fieldName) + "}}"} onCopy={handleCopyTag}>
            <button className={`ml-2 btn btn-white px-2 py-1 w-20 flex-shrink-0 ${isCopied ? "bg-blue text-white" : "bg-white text-blue"}`} onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); }}>
              {isCopied ? "Copied!" : <><DuplicateIcon className="w-4 h-4 mr-1" /> Copy</>}
            </button>
          </CopyToClipboard>
        }
        {(documentType && field.fieldType === "IMAGE") &&
          <button className={`ml-2 btn btn-white px-2 py-1 w-20 flex-shrink-0 ${isCopied ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={handleInsertImage}>
            {isCopied ? "Inserting..." : <><PhotographIcon className="w-4 h-4 mr-1" /> Insert</>}
          </button>
        }
      </div>
    </div>
  );
};

export default WorkflowChatGPTFieldTableRow;
