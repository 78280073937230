import { ChevronRightIcon } from "@heroicons/react/outline";
import { RECIPIENT_COLOUR_MAP } from "../utils";
import { SignatureConfig } from "../interfaces";


type Props = {
  signatureConfig: SignatureConfig;
}

function WorkflowEmailToTags(props: Props) {

  const { signatureConfig } = props;

  const hubspotContactLabel1 = signatureConfig.recipientEmail1.match(/{{__hscontact_(.+?)(?::(.+))?}}/);
  const hubspotContactLabel2 = signatureConfig.recipientEmail2.match(/{{__hscontact_(.+?)(?::(.+))?}}/);
  const hubspotContactLabel3 = signatureConfig.recipientEmail3.match(/{{__hscontact_(.+?)(?::(.+))?}}/);
  const hubspotContactLabel4 = signatureConfig.recipientEmail4.match(/{{__hscontact_(.+?)(?::(.+))?}}/);

  return (
    <div className="flex items-center w-full relative my-1 flex-shrink-0">
      <label className="w-20 font-gilroy font-medium text-gray-500 flex-shrink-0" htmlFor="email-to">To</label>
      <div className='flex flex-col w-full -mr-2'>
        <div className="flex gap-2 items-center w-full relative mt-2 mb-2 select-none overflow-x-auto  pr-2 scrollbar-hidden">
          {signatureConfig.generatePreviewRequest &&
            <div className='flex items-center gap-2 flex-shrink-0'>
              <div className={`bg-gray-600 relative text-white text-xs rounded-full px-2 pt-1 font-semibold flex-shrink-0`}>
                Requested via Preview
              </div>
              <ChevronRightIcon className='w-4 h-4 text-gray-400' />
            </div>
          }
          <div className={`${RECIPIENT_COLOUR_MAP[1][1]} relative text-white text-xs rounded-full px-2 pt-1 font-semibold flex-shrink-0`}>
            1. {hubspotContactLabel1
              ? hubspotContactLabel1[2] || "Contact"
              : signatureConfig.recipientEmail1 || "Email Required"
            }
            {!signatureConfig.recipientEmail1 &&
              <div className='bg-yellow w-2 h-2 rounded-full absolute -right-0.5 top-0'></div>
            }
          </div>
          {signatureConfig.numRecipients >= 2 &&
            <div className='flex items-center gap-2 flex-shrink-0'>
              {!signatureConfig.generatePreviewRequest &&
                <ChevronRightIcon className='w-4 h-4 text-gray-400' />
              }
              <div className={`${RECIPIENT_COLOUR_MAP[2][1]} relative text-white text-xs rounded-full px-2 pt-1 font-semibold flex-shrink-0`}>
                2. {hubspotContactLabel2
                  ? hubspotContactLabel2[2] || "Contact"
                  : signatureConfig.recipientEmail2 || "Email Required"
                }
                {!signatureConfig.recipientEmail2 &&
                  <div className='bg-yellow w-2 h-2 rounded-full absolute -right-0.5 top-0'></div>
                }
              </div>
            </div>
          }
          {signatureConfig.numRecipients >= 3 &&
            <div className='flex items-center gap-2 flex-shrink-0'>
              {!signatureConfig.generatePreviewRequest &&
                <ChevronRightIcon className='w-4 h-4 text-gray-400' />
              }
              <div className={`${RECIPIENT_COLOUR_MAP[3][1]} relative text-white text-xs rounded-full px-2 pt-1 font-semibold flex-shrink-0`}>
                3. {hubspotContactLabel3
                  ? hubspotContactLabel3[2] || "Contact"
                  : signatureConfig.recipientEmail3 || "Email Required"
                }
                {!signatureConfig.recipientEmail3 &&
                  <div className='bg-yellow w-2 h-2 rounded-full absolute -right-0.5 top-0'></div>
                }
              </div>
            </div>
          }
          {signatureConfig.numRecipients >= 4 &&
            <div className='flex items-center gap-2 flex-shrink-0'>
              {!signatureConfig.generatePreviewRequest &&
                <ChevronRightIcon className='w-4 h-4 text-gray-400' />
              }
              <div className={`${RECIPIENT_COLOUR_MAP[4][1]} relative text-white text-xs rounded-full px-2 pt-1 font-semibold flex-shrink-0`}>
                4. {hubspotContactLabel4
                  ? hubspotContactLabel4[2] || "Contact"
                  : signatureConfig.recipientEmail4 || "Email Required"
                }
                {!signatureConfig.recipientEmail4 &&
                  <div className='bg-yellow w-2 h-2 rounded-full absolute -right-0.5 top-0'></div>
                }
              </div>
            </div>
          }
        </div>
        {(signatureConfig.numRecipients >= 2) &&
          <div className='text-gray-600 text-xs font-semibold'>
            {signatureConfig.generatePreviewRequest
              ? "Emails are sent in any order as selected in the preview document"
              : "Emails are sent in sequence, after the first is completed the second will be sent and so on"
            }
          </div>
        }
      </div>
    </div>
  );
}

export default WorkflowEmailToTags;
