import { Transition } from "@headlessui/react";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useModal, WorkflowContext } from "../contexts";
import WorkflowNoSource from "./WorkflowNoSource";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import { DocumentTextIcon, ExclamationIcon, SparklesIcon } from "@heroicons/react/outline";
import WorkflowSignatureConfigItem from "./WorkflowSignatureConfigItem";
import SmallSelectField from "./SmallSelectField";
import { DATE_FORMAT_MAP, TIME_FORMAT_MAP } from "../utils";
import WorkflowSelectModal from "./WorkflowSelectModal";
import TeamsLabel from "./TeamsLabel";
import SmallTextInputField from "./SmallTextInputField";
import ToggleButton from "./ToggleButton";
import ProLabel from "./ProLabel";
import { SignatureConfig } from "../interfaces";
import { useHistory, useRouteMatch } from "react-router-dom";
import PayWallModalPro from "./PayWallModalPro";
import useUser from "../hooks/useUser";
import { putData } from "../backend";
import PayWallModalTeams from "./PayWallModalTeams";
import SettingsTeamModalEdit from "./SettingsTeamModalEdit";

type Props = {
  signatureConfig: SignatureConfig;
  setSignatureConfig: Dispatch<SetStateAction<SignatureConfig | null>>;
  updateSignatureConfig: (updates: any) => void; // TODO: I don't like this, replace this whole "updateSignatureConfig" logic entirely
}

function WorkflowSignatureRequestSidebar(props: Props) {
  const workflowContext = useContext(WorkflowContext)
  const { workflow, mutateWorkflow, showAutomationPanel } = workflowContext;

  const [redirectUrl, setRedirectUrl] = useState(workflow.signatureConfig?.redirectUrl ?? "");
  const [redirectUrlInvalid, setRedirectUrlInvalid] = useState(false);

  const { signatureConfig, setSignatureConfig, updateSignatureConfig } = props;
  const { openModal } = useModal();
  const { user } = useUser();
  const history = useHistory();
  const { url } = useRouteMatch();

  const isWorkflowOwner = (workflow.owner?.id === user?.id);

  function handleEditSignatureRecipients() {
    history.push(url + "/document#signable");
  }

  async function updateExpiryDuration(expiryDurationEnabled: boolean) {
    if (expiryDurationEnabled)
      updateSignatureConfig({ expiryDuration: "30 00:00:00" });
    else
      updateSignatureConfig({ expiryDuration: null });
  }

  async function updategeneratePreviewRequest(generatePreviewRequest: boolean) {
    if (!user?.subscription?.featureSignatures) // TODO: Change this to its own feature flag
      return openModal(<PayWallModalPro />);

    updateSignatureConfig({ generatePreviewRequest })
  }

  async function updateEnableTracking(enableTracking: boolean) {
    if (!user?.subscription?.featureSignatures) // TODO: Change this to its own feature flag
      return openModal(<PayWallModalPro />);

    updateSignatureConfig({ enableTracking })
  }

  async function updateEnableAuditTrail(enableAuditTrail: boolean) {
    if (!user?.subscription?.featureSignatures) // TODO: Change this to its own feature flag
      return openModal(<PayWallModalPro />);

    updateSignatureConfig({ enableAuditTrail })
  }

  async function updateSendFromOwner(sendFromOwner: boolean) {
    if (!signatureConfig)
      return;

    let updatedSignatureConfig = { ...signatureConfig!, sendFromOwner };
    setSignatureConfig(updatedSignatureConfig);

    await putData(`/signatures/signature-configs/${updatedSignatureConfig.id}/owner-update/`, updatedSignatureConfig)
      .catch(console.error);

    mutateWorkflow();
  }


  function handleOpenCustomBranding() {
    if (user?.subscription?.tier !== "teams")
      return openModal(<PayWallModalTeams />);

    openModal(<SettingsTeamModalEdit />);
  }

  function handleSaveRedirectUrl() {
    if (user?.subscription?.tier !== "teams") {
      setRedirectUrlInvalid(true);
      return openModal(<PayWallModalTeams />);
    }

    updateSignatureConfig({ redirectUrl });
  }

  return (
    <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-4 overflow-auto ${showAutomationPanel && "blur-sm"}`}
      enter="transition ease-in-out duration-150 transform"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transition ease-in-out duration-150 transform"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >

      {!workflow.source && <WorkflowNoSource />}

      {workflow.source && <>
        <WorkflowSourceFieldTable workflow={workflow} mutateWorkflow={mutateWorkflow} />
        <hr />

        {workflow.documents.filter(d => d.isSignable).length === 0 &&
          <div className='flex items-center gap-1 text-yellow font-semibold text-sm bg-yellow/20 border-2 border-yellow p-1 rounded'>
            <ExclamationIcon className='w-8 h-8 flex-shrink-0' /> Please select the documents in this workflow to link to this signature request
          </div>
        }

        {workflow.documents.length > 0 &&
          <>
            <div className='flex flex-col gap-2'>
              <div className='flex items-center justify-between'>
                <div className='font-gilroy font-semibold text-gray-600 text-sm'>
                  Signature Request Recipients
                </div>
                {workflow.documents.filter(d => d.isSignable).length > 0 &&
                  <div className='text-xs font-gilroy font-semibold text-gray-400 hover:text-blue cursor-pointer' onClick={handleEditSignatureRecipients}>
                    Add/Remove
                  </div>
                }
              </div>
              {[...Array(signatureConfig.numRecipients)].map((_, index: number) =>
                <WorkflowSignatureConfigItem key={index} recipientNumber={index + 1} signatureConfig={signatureConfig} updateSignatureConfig={updateSignatureConfig} />
              )}
            </div>

            <div className='flex flex-col gap-1'>
              <div className='flex items-center gap-2'>
                <div className='text-sm font-semibold font-gilroy text-gray-600'>
                  Expire Signature Requests
                </div>
              </div>
              <div className='flex items-start gap-2'>
                <ToggleButton value={!!signatureConfig.expiryDuration} onChange={updateExpiryDuration} />
                <div className='text-xs font-semibold text-gray-400'>
                  Have signature&nbsp;requests expire after<br /> 30 days of being sent
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-1'>
              <div className='text-sm font-semibold font-gilroy text-gray-600'>
                Email Tracking
              </div>
              <div className='flex items-start gap-2'>
                <ToggleButton value={signatureConfig.enableTracking} onChange={updateEnableTracking} />
                <div className='text-xs font-semibold text-gray-400 flex-shrink'>
                  View when each signature request email is opened
                </div>
                <ProLabel />
              </div>
            </div>

            <div className='flex flex-col gap-1'>
              <div className='text-sm font-semibold font-gilroy text-gray-600 flex gap-2'>
                Audit Trail
                <div className="flex justify-center items-center bg-gray-100 w-14 rounded-sm shadow flex-shrink-0">
                  <div className="font-gilroy font-semibold text-black text-sm mr-1">New</div>
                  <SparklesIcon className="h-4 w-4 text-yellow" />
                </div>
              </div>
              <div className='flex items-start gap-2'>
                <ToggleButton value={signatureConfig.enableAuditTrail} onChange={updateEnableAuditTrail} />
                <div className='text-xs font-semibold text-gray-400 flex-shrink'>
                  Attach an audit trail to the signed document to show the document history
                </div>
                <ProLabel />
              </div>
            </div>

            <div className='flex flex-col gap-1'>
              <div className='text-sm font-semibold font-gilroy text-gray-600 flex gap-2'>
                Generate Preview
                <div className="flex justify-center items-center bg-gray-100 w-14 rounded-sm shadow flex-shrink-0">
                  <div className="font-gilroy font-semibold text-black text-sm mr-1">New</div>
                  <SparklesIcon className="h-4 w-4 text-yellow" />
                </div>
              </div>
              <div className='flex items-start gap-2'>
                <ToggleButton value={!!signatureConfig.generatePreviewRequest} onChange={updategeneratePreviewRequest} />
                <div className='text-xs font-semibold text-gray-400'>
                  Generate a preview of the document before requesting signatures. Link can be accessed from the outputs page {workflow.source.sourceType === "HUBSPOT" ? "and in HubSpot." : "."}
                </div>
              </div>
            </div>

            {(workflow.team && false) && // Currently disabled
              <div className='flex flex-col gap-1'>
                <div className='text-sm font-semibold font-gilroy text-gray-600'>
                  Send from Workflow Owner
                </div>

                <div className='flex flex-col'>
                  <div className='flex items-center gap-2'>
                    <ToggleButton value={signatureConfig!.sendFromOwner} onChange={updateSendFromOwner} disabled={!isWorkflowOwner} />
                    <div className='text-xs font-semibold text-gray-400 flex-shrink'>
                      Always send emails from the workflow owner
                    </div>
                  </div>
                  {!isWorkflowOwner &&
                    <div className='text-xs font-semibold text-gray-600 w-full text-center mt-1'>
                      This settings can only be configured by the workflow&nbsp;owner: <b>{workflow.owner?.fullName}</b>
                    </div>
                  }
                </div>
              </div>
            }

            <div className='flex flex-col'>
              <div className='font-gilroy font-semibold text-gray-600 text-sm'>
                Date Field Format
              </div>
              <div className='text-xs font-semibold text-gray-400'>
                Control the format in which the automatic date is added to signed documents when using a date field
              </div>
              <div className="flex gap-2 mt-1">
                <SmallSelectField className='flex-1' label="Date Format" value={signatureConfig.dateFormat} onChange={(dateFormat: string) => updateSignatureConfig({ dateFormat })}>
                  {Object.entries(DATE_FORMAT_MAP)
                    .map(([value, label]) => {
                      if (value === workflow.owner?.dateFormat) // Default
                        return <option key={value} value={""}>{label} (Default)</option>;

                      return <option key={value} value={value}>{label}</option>;
                    })}
                </SmallSelectField>
                <SmallSelectField className="flex-1" label="Time Format" value={signatureConfig.dateTimeFormat} onChange={(dateTimeFormat: string) => updateSignatureConfig({ dateTimeFormat })}>
                  {Object.entries(TIME_FORMAT_MAP)
                    .map(([value, label]) => {
                      if (value === workflow.owner?.timeFormat) // Default
                        return <option key={value} value={""}>{label} (Default)</option>;

                      return <option key={value} value={value}>{label}</option>;
                    })}
                </SmallSelectField>
              </div>
            </div>

            <div className='flex flex-col gap-1'>
              <div className='flex items-center gap-2'>
                <div className='text-sm font-semibold font-gilroy text-gray-600'>
                  Custom Confirmation Page
                </div>
                <TeamsLabel />
              </div>
              <div className='text-xs font-semibold text-gray-400'>
                When a recipient has completed a signature request redirect them automatically to your own webpage
              </div>
              <SmallTextInputField value={redirectUrl} onChange={value => { setRedirectUrl(value); setRedirectUrlInvalid(false); }} onEnter={handleSaveRedirectUrl} label='URL' placeholder='https://www.example.com/thankyou' invalid={redirectUrlInvalid} />
            </div>

            <div className='flex flex-col gap-1'>
              <div className='flex items-center gap-2'>
                <div className='text-sm font-semibold font-gilroy text-gray-600'>
                  Custom eSign Portal Branding
                </div>
                <TeamsLabel />
              </div>
              <div className='text-xs font-semibold text-gray-400'>
                Control the appearance of the eSign portal recipients will visit when to complete your signature request
              </div>
              <button className='text-sm btn btn-white py-1' onClick={handleOpenCustomBranding} >Customise Branding</button>
            </div>
          </>
        }

        {workflow.documents.length === 0 && <>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <div className="flex items-center font-gilroy font-semibold text-gray-600">
                <DocumentTextIcon className="w-6 h-6 mr-1" /> Add document
              </div>
              <div className="font-semibold text-xs text-gray-400">
                Add a templated document to be included with this email
              </div>
            </div>
            <button className="btn btn-white w-40 text-xs" onClick={() => openModal(<WorkflowSelectModal title="Add document" subtitle="Add a templated document to be included with this email" showDocuments {...workflowContext} />)}>
              Add
            </button>
          </div>
        </>}

      </>}
    </Transition.Child>
  );
}

export default WorkflowSignatureRequestSidebar;
