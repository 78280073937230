import { XIcon } from "@heroicons/react/outline";
import { Attachment } from "../interfaces";
import { BLOCK_ICON_MAP, MIMETYPE_ICON_MAP } from "../utils";

type Props = {
  attachments: Array<Attachment>;
  addingAttachment: boolean;
  removingAttachmentId?: string;
  updatingAttachmentId?: string;
  removeAttachment: (id: string) => void;
  updateAttachment: (attachmentId: string, sharingMode: string) => void;
}

function WorkflowEmailAttachments(props: Props) {
  const { attachments, addingAttachment, removeAttachment, removingAttachmentId, updatingAttachmentId, updateAttachment } = props;
  return (
    <div className='mt-auto flex-shrink-0 flex flex-col pt-2'>
      {addingAttachment &&
        <div className="select-none cursor-default flex items-center bg-gray-200 py-1 px-2 rounded-sm shadow-sm max-w-[412px] mb-1">
          <div className='w-4 h-4 mr-2 flex-shrink-0' />
          <div className={`text-gray-600 text-sm font-semibold truncate w-96`}>
            Adding attachment...
          </div>
        </div>
      }

      {attachments.map((attachment: Attachment) =>
        <div key={attachment.id} className="select-none cursor-default flex items-center bg-gray-200 py-1 px-2 rounded-sm shadow-sm max-w-[412px] mb-1">
          <img className='w-4 h-4 mr-2 flex-shrink-0' src={MIMETYPE_ICON_MAP[attachment.attachmentMimeType ?? "application/pdf"] ?? BLOCK_ICON_MAP["GOOGLE_DRIVE"]} alt="Attachment icon" />
          <div className={`text-gray-600 text-sm font-semibold truncate w-96 ${removingAttachmentId === attachment.id ? "line-through" : ""}`} onClick={() => attachment.driveFile && window.open(attachment.driveFile?.url)}>
            {attachment.attachmentName}
          </div>
          {attachment.attachmentType === "INSERT_OUTPUT_AS_LINK" &&
            <div className='text-xs mr-2'>
              <select value={attachment.sharingMode} className='font-gilroy font-semibold bg-gray-50 shadow rounded py-px' onChange={e => updateAttachment(attachment.id, e.currentTarget.value)} disabled={updatingAttachmentId === attachment.id}>
                <option value="VIEWER">Viewer</option>
                <option value="COMMENTER">Commenter</option>
                <option value="EDITOR">Editor</option>
              </select>
            </div>
          }
          <XIcon className="w-5 h-5 text-gray-400 hover:text-gray-600 ml-auto cursor-pointer flex-shrink-0" onClick={() => removeAttachment(attachment.id)} />
        </div>
      )}
    </div>
  );
}

export default WorkflowEmailAttachments;
